import React, {useState, useEffect, useCallback} from 'react';
import {Button} from "devextreme-react/button";



export default function Help() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Помощь</h2>

            <a href={'https://t.me/+4zdUFO4mbDAxNjli'} target={'_blank'}>
                <center><Button

                    text="Задать вопрос"
                    type="success"
                    stylingMode="outlined"
                    //onClick={() => PoiskPoid()}
                    icon="help"


                />
                </center>
            </a>
            <center>При обращении в техническую поддержку обязательно указывайте код пищеблока в базе ФЦМПО</center>
            <div className={'content-block'}>

                <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                    <div className={'title-table'}><b>ШАБЛОНЫ</b></div>


                    <ul>
                        <hr></hr>
                        <li><a href={require('./file/ГГГГ-ММ-ДД-sm.xlsx')} download="ГГГГ-ММ-ДД-sm"
                               target="_blank"
                               rel="noreferrer">Ежедневное меню</a></li>

                        <li><a href={require('./file/rashir/ГГГГ-ММ-ДД-sm.xlsx')} download="ГГГГ-ММ-ДД-sm"
                               target="_blank"
                               rel="noreferrer">Ежедневное меню (раcширенный вариант)</a></li>
                        <hr></hr>

                        <li><a href={require('./file/findex.xlsx')} download="findex"
                               target="_blank"
                               rel="noreferrer">Рейтинг общественной оценки (findex.xlsx)</a>
                        </li>
                        <hr></hr>

                        <li><a href={require('./file/tm2025-sm.xlsx')} download="tm2025-sm"
                               target="_blank"
                               rel="noreferrer">Типовое меню 2×5</a></li>

                        <li><a href={require('./file/tm2025-2x6.xlsx')} download="tm2025-sm"
                               target="_blank"
                               rel="noreferrer">Типовое меню 2×6</a></li>

                        <li><a href={require('./file/tm2025-sm-2х7.xlsx')} download="tm2025-sm"
                               target="_blank"
                               rel="noreferrer">Типовое меню 2×7</a></li>

                        <li><a href={require('./file/tm2025-sm-3x5.xlsx')} download="tm2025-sm"
                               target="_blank"
                               rel="noreferrer">Типовое меню» 3×5</a></li>

                        <li><a href={require('./file/tm2025-4x5.xlsx')} download="tm2025-sm"
                               target="_blank"
                               rel="noreferrer">Типовое меню 4×5</a></li>

                        <hr></hr>

                        <li><a href={require('./file/kp2025.xlsx')} download="kp2025"
                               target="_blank"
                               rel="noreferrer">Календарь питания» (2025, 2×5, с примером заполнения)</a></li>

                    </ul>
                </div>
            </div>
            <div className={'content-block'}>

                <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>


                    <div className={'title-table'}><b>ИНСТРУКЦИИ</b></div>
                    <ul>
                        <li><a href={require('./file/Инструкция.pdf')} download="Инструкция"
                               target="_blank"
                               rel="noreferrer">Общая инструкция по мониторингу</a></li>
                        <li><a href={require('./file/tp_instr.pdf')} download="Инструкция_ТП_КП"
                               target="_blank"
                               rel="noreferrer">Инструкция по Типовому меню и Календарю питания</a></li>

                    </ul>
                </div>
            </div>
            <div className={'content-block'}>

                <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                    <div className={'title-table'}><b>НОРМАТИВНАЯ БАЗА</b></div>
                    <ul>
                        <li><a href={require('./file/1158.pdf')} download="1158"
                               target="_blank"
                               rel="noreferrer">Письмо Министерства просвещения о размещении меню ГД-1158/01 от
                            17.05.2021</a></li>


                        <li><a href={require('./file/1153.pdf')} download="1158"
                               target="_blank"
                               rel="noreferrer">Письмо Министерства просвещения о направлении информации АК-1153/10 от
                            23.08.2022</a></li>


                    </ul>
                </div>
            </div>
            <div className={'content-block'}>

                <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                    <div className={'title-table'}><b>ВИДЕО</b></div>
                    <ul>
                        <li>Запись вебинаров на тему «Школьное питание»
                            <a href={'https://rutube.ru/channel/35063550'}> https://rutube.ru/channel/35063550/</a>
                        </li>


                    </ul>
                </div>
            </div>


        </React.Fragment>
    )
}